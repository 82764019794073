<template>
    <div class="accordion">
        <div class="accordion__header" v-on:click="toggle">
            <slot name="header">HINT</slot>
            <i class="accordion__icon" v-bind:class="{ rotate: show }">
                <img alt="" src="@/assets/images/arrow-d.svg">
            </i>
        </div>
        <div v-show="show" class="accordion__body">
            <div class="accordion__bodyInner">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            first: true
        };
    },
    methods: {
        toggle: function () {
            if(!this.show) {
                this.$emit('open');
            }
            this.show = !this.show;
        },
    }
}
</script>


<style lang="less" scoped>

.accordion {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;

    &__header {
        padding: 20px;
        position: relative;
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.14px;
        color: #1A2744;
        font-weight: bold;

        @media screen and (max-width: 1440px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__icon {
        position: absolute;
        height: 14px;
        width: 24px;
        top: 29px;
        right: 20px;
        transform: rotate(180deg) scale(0.7);
        transform-origin: center center;
        transition-duration: 0.3s;

        &.rotate {
            transform: rotate(0deg) scale(0.7);
            top: 22px;
        }
    }

    &__body {
        overflow: hidden;
        background-color: #fff;
        border-top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        transition: 150ms ease-out;
    }

    &__bodyInner {
        padding: 0 20px 20px 20px;
        overflow-wrap: break-word;
    }

    &.white {
        background-color: #fff;
        
        .accordion__body {
            border-color: #fff;
        }
    }
}

</style>
